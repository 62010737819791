import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT;

export const companyApi = createApi({
  reducerPath: "companyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/admin/company/`,
  }),
  tagTypes: ["Companies"],
  endpoints: (builder) => ({
    createCompany: builder.mutation({
      query(data) {
        return {
          url: "",
          method: "POST",
          credentials: "include",
          body: data,
        };
      },
      invalidatesTags: [{ type: "Companies", id: "LIST" }],
    }),
    getCompanies: builder.query({
      query() {
        return {
          url: "",
          credentials: "include",
        };
      },
      transformResponse: (result) => result.data.companies,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Companies", id })),
              { type: "Companies", id: "LIST" },
            ]
          : [{ type: "Companies", id: "LIST" }],
    }),
    UpdateCompany: builder.mutation({
      query({ id, company }) {
        return {
          url: `${id}`,
          method: "PATCH",
          credentials: "include",
          body: company,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "Companies", id },
              { type: "Companies", id: "LIST" },
            ]
          : [{ type: "Companies", id: "LIST" }],
    }),
    deleteCompany: builder.mutation({
      query(id) {
        return {
          url: `${id}`,
          method: "DELETE",
          credentials: "include",
        };
      },
      invalidatesTags: [{ type: "Companies", id: "LIST" }],
    }),
  }),
});

export const {
  useCreateCompanyMutation,
  useGetCompaniesQuery,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation
} = companyApi;
