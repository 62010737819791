import { useEffect, useState } from "react";
import { object, string } from "zod";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import {
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
  useGetCompaniesQuery
} from "../../redux/api/companyApi";
import FormInput from "../../components/FormInput";
import { Edit, AlignJustifyIcon, Trash2 } from "lucide-react";
import { useSidebar } from "../../lib/hooks/use-sidebar";

const companySchema = object({
  name: string().min(1, "部署名を入力してください。")
  // .refine(async (e) => {
  //   const bool = await checkIfUserIdIsValid(e);
  //   return !bool;
  // }, "This user ID was already taken."),
});

const Company = () => {
  const {data: companyList, refetch } = useGetCompaniesQuery();
  
  const { setIsOpen } = useSidebar();
  const [createCompany, createStatus] = useCreateCompanyMutation();
  const [updateCompany, updateStatus] = useUpdateCompanyMutation();
  const [deleteCompany, deleteStatus] = useDeleteCompanyMutation();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("selectedCompId")) return;
    localStorage.removeItem("selectedCompId")
  }, [])

  const methods = useForm({
    resolver: zodResolver(companySchema),
  });

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isEditing && selectedId) {
      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/admin/company/${selectedId}`,
        { credentials: "include" }
      )
        .then((response) => response.json())
        .then(({ data }) => {
          setValue("name", data.name);
        });
    } else reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, isEditing]);

  useEffect(() => {
    if (createStatus.isSuccess) {
      toast.success("部署を追加しました。");
      refetch();
    }

    if (createStatus.isError) {
      if (Array.isArray(createStatus.error.data.error)) {
        createStatus.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(createStatus.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus.isLoading]);

  useEffect(() => {
    if (updateStatus.isSuccess) {
      toast.success("部署を更新しました。");
      refetch();
      localStorage.removeItem("selectedCompId");
      setIsEditing(false)
    }

    if (updateStatus.isError) {
      if (Array.isArray(updateStatus.error.data.error)) {
        updateStatus.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(updateStatus.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus.isLoading]);

  useEffect(() => {
    if (deleteStatus.isSuccess) {
      toast.success("部署を削除しました。");
      navigate("/chat/free/template/free");
    }

    if (deleteStatus.isError) {
      if (Array.isArray(deleteStatus.error.data.error)) {
        deleteStatus.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(deleteStatus.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus.isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      if (!isEditing) reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmitHandler = (values) => {
    switch (isEditing) {
      case false:
        createCompany(values);
        break;
      case true:
        updateCompany({ id: selectedId, company: values });
        break;
      default:
    }
  };

  return (
    <main className="flex-grow overflow-auto flex flex-col">
      <div className="flex items-center gap-2">
        <button onClick={() => { setIsOpen(true) }} className="p-1 md:hidden ml-2 "><AlignJustifyIcon size={18} /></button>
        <h1 className="text-xl m-3 font-bold">部署一覧</h1>
      </div>
      {/* <div className="flex p-3 gap-2 items-center">
        <Search size={18} /> <input type="text" 
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="検索" />
      </div> */}
      <div className="m-3 p-4 bg-white rounded-lg">
        <h3 className="text-lg font-semibold mb-4">
          {isEditing ? "編集" : "新規登録"}
        </h3>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
          >
            <FormInput
              name="name"
              type="text"
              placeholder="部署名"
              className="w-full p-2 mb-2 border border-gray-300 rounded"
            />
            <div className="flex justify-end">
              {isEditing && (
                <>
                  <button
                    className="px-4 py-2 bg-gray-400 text-white rounded mr-2"
                    type="button"
                    onClick={() => {
                      setIsEditing(false);
                      localStorage.removeItem("selectedCompId");
                    }}
                  >
                    キャンセル
                  </button>
                  <button
                    className="px-4 py-2 bg-red-600 text-white rounded mr-2"
                    type="button"
                    onClick={() => {
                      if (window.confirm("削除しますか？"))
                        deleteCompany(selectedId);
                    }}
                  >
                    削除
                  </button>
                </>
              )}
              <button
                className="px-4 py-2 bg-indigo-600 text-white rounded"
                type="submit"
              >
                保存
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
      <table className="m-3 border-collapse border bg-white rounded-lg border-gray-300 overflow-hidden">
        <thead className="bg-gray-50">
          <tr>
            <th className="py-2 px-4 border-b border-gray-300 text-left">ID</th>
            <th className="py-2 px-4 border-b border-gray-300 text-left">部署名</th>
            <th style={{width: '100px'}} className="py-2 px-4 border-b border-gray-300 text-left">操作</th>
          </tr>
        </thead>
        <tbody>
          {
            companyList?.length ? companyList.map((company, i) =>
              <tr key={i} className="">
                <td className="py-2 px-4 border-b border-gray-300">{company.id}</td>
                <td className="py-2 px-4 border-b border-gray-300">{company.name}</td>
                <td className="py-2 px-4 border-b border-gray-300">
                  <button
                    onClick={() => { localStorage.setItem("selectedCompId", company.id); setSelectedId(company.id); setIsEditing(true); }}
                    className="text-indigo-600 hover:text-indigo-900"
                  >
                    <Edit size={18} />
                  </button>
                  <button
                    onClick={() => {
                      if (window.confirm("削除しますか？"))
                        deleteCompany(company.id);
                    }}
                    className="ml-2 text-red-600 hover:text-red-900"
                  >
                    <Trash2 size={18} />
                  </button>
                </td>
              </tr>
            ) :
            <tr>
              <td colSpan="3" className="py-2 px-4 text-center">部署なし</td>
            </tr>
          }
        </tbody>
      </table>
    </main>
  );
};

export default Company;
