import { useSelector } from "react-redux";
import AdminSidebar from "./admin/AdminSidebar";
import UserSidebar from "./user/UserSidebar";
import { useSidebar } from '../lib/hooks/use-sidebar';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { X } from "lucide-react";

const Sidebar = ({ isDifyMode, setIsDifyMode, studio, setStudio }) => {
  const user = useSelector((state) => state.userState.user);
  const {isOpen, setIsOpen} = useSidebar();
  const location = useLocation();

  useEffect(() => {
    if (!isOpen) return;
    setIsOpen(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className="flex relative">
      {isOpen && <button onClick={() => { setIsOpen(false) }} className="absolute top-6 left-5 p-1 md:hidden" ><X color="white" size={20} /></button>}
      {
        user?.role === 'admin' ? <AdminSidebar /> : <UserSidebar isDifyMode={isDifyMode} setIsDifyMode={setIsDifyMode} studio={studio} setStudio={setStudio} />
      }
    </div>
  );
};

export default Sidebar;
