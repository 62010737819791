import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { object, string } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FormInput from "../components/FormInput";

const loginSchema = object({
  email: string()
    .min(1, "メールアドレスを入力してください。")
    .email("無効なメールアドレスです。"),
  password: string()
    .min(1, "パスワードを入力してください。")
    .min(8, "パスワードは8文字以上入力してください。")
    .max(32, "パスワードは32文字以下で入力してください"),
});

const LoginPage = () => {
  const methods = useForm({
    resolver: zodResolver(loginSchema),
  });
  const [isLoading, setIsLoading] = useState(false); // ローディング状態
  const [isAuthLoading, setIsAuthLoading] = useState(true); // 認証確認中のフラグ
  const navigate = useNavigate();
  const { handleSubmit } = methods;
  const onSubmitHandler = async (values) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/auth/login/`,
        {
          credentials: "include",
          method: "POST",
          body: JSON.stringify(values),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        if (Array.isArray(errorData.error)) {
          errorData.error.forEach((el) =>
            toast.error(el.message, { position: "top-right" })
          );
        } else {
          toast.error(errorData.message, { position: "top-right" });
        }
      } else {
        await response.json();
        toast.success("ログインしました");
        // 認証情報が確実に保存された後にリダイレクトするために少し遅らせる
        setTimeout(() => {
          navigate("/chat/free/template/free");
        }, 500); // 500ミリ秒の遅延
      }
    } catch (error) {
      toast.error("サーバーエラーが発生しました。もう一度お試しください。", {
        position: "top-right",
      });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/api/auth/check`, // 認証チェック用のエンドポイント
          { credentials: "include" }
        );
        if (response.ok) {
          navigate("/chat/free/template/free");
        } else {
          setIsAuthLoading(false); // 認証情報がなければローディング終了
        }
      } catch (error) {
        setIsAuthLoading(false); // エラー時もローディング終了
      }
    };
    checkAuth();
  }, [navigate]);
  if (isAuthLoading) {
    return null; // 認証チェックが完了するまで何も表示しない
  }
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            AIMentor
          </h2>
        </div>
        <FormProvider {...methods}>
          <form
            className="mt-8 space-y-6"
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
          >
            <div className="rounded-md shadow-sm -space-y-px">
              <FormInput name="email" label="メールアドレス" type="email" />
              <FormInput name="password" label="パスワード" type="password" />
            </div>
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                disabled={isLoading}
              >
                {isLoading ? "ログイン中..." : "ログイン"}
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default LoginPage;
