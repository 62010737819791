import React, { createContext, useContext, useState } from 'react';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [isGenerating, setIsGenerating] = useState(false);

  return (
    <ChatContext.Provider value={{ isGenerating, setIsGenerating }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);