import { useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  useDeleteKnowledgeMutation,
  useGetKnowledgesQuery  
} from "../../redux/api/knowledgeApi";
import { Edit, Trash2, AlignJustifyIcon } from "lucide-react";
import { useSidebar } from "../../lib/hooks/use-sidebar";

const Knowledges = () => {

  const { data: knowledgeList, isError, error } = useGetKnowledgesQuery();

  const [deleteKnowledge, deleteStatus] = useDeleteKnowledgeMutation();
  const { setIsOpen } = useSidebar();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError && error?.data?.message === "You are not logged in") {
      logout();
    }
  }, [isError, error]);

  const logout = () => {
    localStorage.removeItem('access_token');
    navigate('/login');
  };

  useEffect(() => {
    if (deleteStatus.isSuccess) {
      toast.success("ナレッジを削除しました。");
      navigate("/admin/knowledges");
    }

    if (deleteStatus.isError) {
      if (Array.isArray(deleteStatus.error.data.error)) {
        deleteStatus.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(deleteStatus.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus.isLoading]);

  return (
    <main className="flex-grow overflow-auto flex flex-col">
      <div className="flex justify-between items-center mt-4">
        <div className="flex items-center gap-2">
          <button onClick={() => { setIsOpen(true) }} className="p-1 md:hidden ml-2 "><AlignJustifyIcon size={18} /></button>
          <h1 className="text-xl m-3 font-bold">ナレッジ一覧</h1>
        </div>
        <button onClick={() => { navigate("/admin/knowledge/new") }} className="px-4 py-2 bg-blue-600 text-white rounded mr-2">新規登録</button>
      </div>
      {/* <div className="flex p-3 gap-2 items-center">
        <Search size={18} /> <input type="text" 
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="検索" />
      </div> */}
      <table className="m-3 border-collapse border bg-white rounded-lg border-gray-300 overflow-hidden">
        <thead className="bg-gray-50">
          <tr>
            <th className="py-2 px-4 border-b border-gray-300 text-left">タイトル</th>
            <th className="py-2 px-4 border-b border-gray-300 text-left">操作</th>
          </tr>
        </thead>
        <tbody>
          { knowledgeList?.length ? knowledgeList && knowledgeList.map(item =>
              <tr key={item.id} className="">
                <td className="py-2 px-4 border-b border-gray-300">{item.title}</td>
                <td className="py-2 px-4 border-b border-gray-300">
                  <button
                    onClick={() => {
                      navigate(`/admin/knowledge/${item.id}`, {
                        state: { 
                          title: item.title, 
                          content: item.content 
                        }
                      })
                    }}
                    className="text-indigo-600 hover:text-indigo-900"
                  >
                    <Edit size={18} />
                  </button>
                  <button
                    onClick={() => {
                      if (window.confirm("削除しますか？"))
                        deleteKnowledge(item.id);
                    }}
                    className="ml-2 text-red-600 hover:text-red-900"
                  >
                    <Trash2 size={18} />
                  </button>
                </td>
              </tr>
            ) :
            <tr>
              <td colSpan="5" className="py-2 px-4 text-center">ナレッジなし</td>
            </tr>
          }
        </tbody>
      </table>
    </main>
  );
};

export default Knowledges;
