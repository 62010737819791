import { useCookies } from "react-cookie";
import { Navigate, Outlet } from "react-router-dom";
import { userApi } from "../redux/api/userApi";
import FullScreenLoader from "./FullScreenLoader";

const RequireUser = ({ allowedRoles }) => {
  const [cookies] = useCookies(["logged_in"]);

  const { isLoading, isFetching } = userApi.endpoints.getMe.useQuery(null, {
    skip: false,
    refetchOnMountOrArgChange: true,
  });

  const loading = isLoading || isFetching;

  const user = userApi.endpoints.getMe.useQueryState(null, {
    selectFromResult: ({ data }) => data,
  });

  if (loading) {
    return <FullScreenLoader />;
  }

  return cookies.logged_in && allowedRoles.includes(user.role) ? (
    <Outlet />
  ) : cookies.logged_in ? user.role === 'admin' ?
    <Navigate to="/admin/users" replace />
  : (
    <Navigate to="/unauthorized" replace />
  ) : (
    <Navigate to="/login" replace />
  );
};

export default RequireUser;
