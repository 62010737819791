import { Controller, useFormContext } from "react-hook-form";

const FormSelect = ({ name, label, options, ...otherProps }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field }) => (
        <div className="mb-2">
          <label htmlFor={name}>{label}</label>
          <select
            {...field}
            className={`appearance-none rounded-none relative block w-full px-3 py-2 border  placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm ${
              errors[name] ? "border-red-600" : "border-gray-300"
            }`}
            {...otherProps}
          >
            {
              options?.map((opt, i) => (
                <option key={i} value={opt.value}>{opt.label}</option>
              ))
            }
          </select>
          <p className="text-red-600">
            {errors[name] ? errors[name].message : ""}
          </p>
        </div>
      )}
    />
  );
};

export default FormSelect;
