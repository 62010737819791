import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar";

const Layout = ({ isDifyMode, setIsDifyMode, studio, setStudio }) => {
  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar isDifyMode={isDifyMode} setIsDifyMode={setIsDifyMode} studio={studio} setStudio={setStudio} />
      <Outlet />
    </div>
  );
};

export default Layout;
