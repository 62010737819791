import { IconCheck, IconCopy } from './ui/icons'
import { useCopyToClipboard } from '../lib/hooks/use-copy-to-clipboard'

export function ChatMessageActions({
  message,
  className,
  ...props
}) {
  const { isCopied, copyToClipboard } = useCopyToClipboard({ timeout: 2000 })

  const onCopy = () => {
    if (isCopied) return
    copyToClipboard(message)
  }

  return (
    <div
      className={`flex items-center justify-end transition-opacity group-hover:opacity-100 md:absolute md:-right-10 md:top-0 md:opacity-0 ${className}`}
      {...props}
    >
      <button className="hover:bg-black hover:text-white h-8 w-8 p-0 inline-flex items-center justify-center rounded-md text-sm font-medium shadow ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50" onClick={onCopy}>
        {isCopied ? <IconCheck /> : <IconCopy />}
        <span className="sr-only">Copy message</span>
      </button>
    </div>
  )
}
