import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT;

export const knowledgeApi = createApi({
  reducerPath: "knowledgeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/admin/knowledge/`,
  }),
  tagTypes: ["Knowledges"],
  endpoints: (builder) => ({
    createKnowledge: builder.mutation({
      query(data) {
        return {
          url: "",
          method: "POST",
          credentials: "include",
          body: data,
        };
      },
      invalidatesTags: [{ type: "Knowledges", id: "LIST" }],
    }),
    getKnowledges: builder.query({
      query() {
        return {
          url: "",
          credentials: "include",
        };
      },
      transformResponse: (result) => result.data.knowledges,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Knowledges", id })),
              { type: "Knowledges", id: "LIST" },
            ]
          : [{ type: "Knowledges", id: "LIST" }],
    }),
    updateKnowledge: builder.mutation({
      query({ id, knowledge }) {
        return {
          url: `${id}`,
          method: "PATCH",
          credentials: "include",
          body: knowledge,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "Knowledges", id },
              { type: "Knowledges", id: "LIST" },
            ]
          : [{ type: "Knowledges", id: "LIST" }],
    }),
    deleteKnowledge: builder.mutation({
      query(id) {
        return {
          url: `${id}`,
          method: "DELETE",
          credentials: "include",
        };
      },
      invalidatesTags: [{ type: "Knowledges", id: "LIST" }],
    }),
  }),
});

export const {
  useCreateKnowledgeMutation,
  useGetKnowledgesQuery,
  useUpdateKnowledgeMutation,
  useDeleteKnowledgeMutation,
} = knowledgeApi;
