const FullScreenLoader = () => {
  return (
    <div className="h-screen flex justify-center items-center">
      <div className="h-full w-full bg-[#EA204933] flex justify-center items-center">
        <div className="lds-dual-ring"></div>
      </div>
    </div>
  );
};

export default FullScreenLoader;
