import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT;

export const chatApi = createApi({
  reducerPath: "chatApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/chat/`,
  }),
  tagTypes: ["Chats"],
  endpoints: (builder) => ({
    createChat: builder.mutation({
      query(data) {
        return {
          url: "",
          method: "POST",
          credentials: "include",
          body: data,
        };
      },
      transformResponse: (result) => result.data,
      invalidatesTags: [{ type: "Chats", id: "LIST" }],
    }),
    getReplyFromAI: builder.mutation({
      query(data) {
        return {
          url: "reply",
          method: "POST",
          credentials: "include",
          body: data,
        };
      },
      transformResponse: (result) => result.data,
      invalidatesTags: [{ type: "Chats", id: "LIST" }],
    }),
    getChats: builder.query({
      query() {
        return {
          url: "",
          credentials: "include",
        };
      },
      transformResponse: (result) => result.data.chats,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Chats", id })),
              { type: "Chats", id: "LIST" },
            ]
          : [{ type: "Chats", id: "LIST" }],
    }),
    updateChat: builder.mutation({
      query({ id, chat }) {
        return {
          url: `${id}`,
          method: "PATCH",
          credentials: "include",
          body: chat,
        };
      },
    }),
    getChat: builder.query({
      query(id) {
        return {
          url: `${id}`,
          credentials: "include",
        };
      },
      transformResponse: (result) => result.data,
    }),
  }),
});

export const {
  useCreateChatMutation,
  useGetChatQuery,
  useGetChatsQuery,
  useUpdateChatMutation,
  useGetReplyFromAIMutation
} = chatApi;
