import { useEffect } from "react";
import { AlignJustifyIcon } from "lucide-react";
import { object, string } from "zod";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { useSidebar } from "../lib/hooks/use-sidebar";
import {
  useUpdatePasswordMutation,
} from "../redux/api/userApi";
import FormInput from "../components/FormInput";
const updatePasswordSchema = object({
  oldPassword: string()
  .min(8, "パスワードは 8 文字以上である必要があります。"),
  password: string()
  .min(1, "パスワードを入力してください。")
  .min(8, "パスワードは 8 文字以上である必要があります。")
  .max(32, "パスワードは 32 文字未満にする必要があります。"),
  passwordConfirm: string().min(1, "確認パスワードを入力してください。"),
}).refine((data) => data.password === data.passwordConfirm, {
  path: ["passwordConfirm"],
  message: "パスワードが一致しません。",
});

const UpdatePassword = () => {
  const [updatePassword, updateStatus] = useUpdatePasswordMutation();
  const {setIsOpen } = useSidebar();

  useEffect(() => {
    if (!localStorage.getItem("selectedId")) return;
    localStorage.removeItem("selectedId")
  }, [])

  const methods = useForm({
    resolver: zodResolver(updatePasswordSchema),
  });

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (updateStatus.isSuccess) {
      toast.success("パスワードを変更しました。");
      reset();
    }

    if (updateStatus.isError) {
      if (Array.isArray(updateStatus.error.data.error)) {
        updateStatus.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(updateStatus.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus.isLoading]);


  useEffect(() => {
    if (isSubmitSuccessful) {
     reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmitHandler = (values) => {
    updatePassword(values)
  };

  
  return (
      <div className="m-3 p-4 bg-white rounded-lg">
          <h3 className="text-lg font-semibold mb-4">
            パスワード変更
          </h3>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
          >
            <FormInput
              name="oldPassword"
              type="password"
              placeholder="現在のパスワード"
              className="w-full p-2 mb-2 border border-gray-300 rounded"
            />
            <FormInput
              name="password"
              type="password"
              placeholder="新しいパスワード"
              className="w-full p-2 mb-2 border border-gray-300 rounded"
            />
            <FormInput
              name="passwordConfirm"
              type="password"
              placeholder="パスワード確認"
              className="w-full p-2 mb-2 border border-gray-300 rounded"
            />
            <div className="flex justify-end">
              <button
                className="px-4 py-2 bg-indigo-600 text-white rounded"
                type="submit"
              >
                保存
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
  );
};

export default UpdatePassword;
