import React from "react";
import { useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import ChatBotPage from "./pages/chatbot.page";
import ChatPage from "./pages/chat.page";
import LoginPage from "./pages/login.page";
import RegisterPage from "./pages/register.page";
import UpdateAccount from "./pages/account.page";
import UpdatePassword from "./pages/password.page";

import RequireUser from "./components/requireUser";
import Layout from "./components/layout";
import Template from "./components/Template";
import User from "./components/admin/User";
import Knowledge from "./components/admin/Knowledge";
import Studio from "./components/admin/Studio";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Users from "./pages/admin/user.page";
import Company from "./pages/admin/company.page";
import Studios from "./pages/admin/studio.page";
import Knowledges from "./pages/admin/knowledge.page";
import { ChatProvider } from './lib/hooks/use-chat'; // Import chat context
import { SidebarProvider } from './lib/hooks/use-sidebar'; // Import sidebar context

const App = () => {
  const [isDifyMode, setIsDifyMode] = useState(true);
  const [studio, setStudio] = useState(0);

  return (
    <>
      <ToastContainer />
      <SidebarProvider>
        <ChatProvider>
          <Routes>
            <Route path="/">
              <Route path="login" element={<LoginPage />} />
              <Route path="chatbot" element={<ChatBotPage />} />
              <Route path="register" element={<RegisterPage />} />
              <Route element={<RequireUser allowedRoles={["user"]} />}>
                <Route element={<Layout isDifyMode={isDifyMode} setIsDifyMode={setIsDifyMode} studio={studio} setStudio={setStudio} />}>
                  <Route path="/" element={<Navigate to="/chat/free/template/free" replace />} />
                  <Route
                    path="/password"
                    element={<UpdateAccount />}
                  />
                  {/* <Route
                    path="/password"
                    element={<UpdatePassword />}
                  /> */}
                  <Route
                    path="chat/:chatId/template/:templateId"
                    element={<ChatPage isDifyMode={isDifyMode} studio={studio}/>}
                  />
                  <Route path="template">
                    <Route path="new" element={<Template type="add" />} />
                    <Route
                      path="edit/:templateId"
                      element={<Template type="edit" />}
                    />
                  </Route>
                </Route>
              </Route>
              <Route element={<RequireUser allowedRoles={["admin"]} />}>
                <Route element={<Layout />}>
                  <Route path="admin">
                    <Route path="users" element={<Users />} />
                    <Route path="company" element={<Company />} />
                    <Route path="knowledges" element={<Knowledges />} />
                    <Route path="studios" element={<Studios />} />
                    <Route
                      path="password"
                      element={<UpdateAccount />}
                    />
                    <Route path="template">
                      <Route path="new" element={<Template type="add" />} />
                      <Route
                        path="edit/:templateId"
                        element={<Template type="edit" />}
                      />
                    </Route>
                    <Route path="user">
                      <Route path="new" element={<User type="add" />} />
                      <Route
                        path=":userId"
                        element={<User type="edit" />}
                      />
                    </Route>
                    <Route path="knowledge">
                      <Route path="new" element={<Knowledge type="add" />} />
                      <Route
                        path=":knowledgeId"
                        element={<Knowledge type="edit" />}
                      />
                    </Route>
                    <Route path="studio">
                      <Route path="new" element={<Studio type="add" />} />
                      <Route
                        path=":studioId"
                        element={<Studio type="edit" />}
                      />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Routes>
        </ChatProvider>
      </SidebarProvider>
    </>
  );
};

export default App;
