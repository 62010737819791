import { AlignJustifyIcon } from "lucide-react";
import { useSidebar } from "../lib/hooks/use-sidebar";

import UpdatePassword from "./password.page";
import UpdateAvatar from "./avatar.page";

const UpdateAccount = () => {
  const {setIsOpen } = useSidebar();
  
  return (
    <main className="flex-grow overflow-auto flex flex-col">
      <div className="flex items-center gap-2 m-3">
        <button onClick={() => { setIsOpen(true) }} className="p-1 md:hidden "><AlignJustifyIcon size={18} /></button>
        <h1 className="text-xl font-bold">アカウント変更</h1>
      </div>
      <UpdatePassword />
      <UpdateAvatar />
    </main>
  );
};

export default UpdateAccount;
