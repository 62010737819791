import { useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { object, string } from "zod";
import { FormProvider, useForm } from "react-hook-form";
import FormInput from "../../components/FormInput";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  useGetStudiosQuery,
  useDeleteStudioMutation,
  useUpdateStudioMutation
} from "../../redux/api/studioApi";
import { Edit, Trash2, AlignJustifyIcon } from "lucide-react";
import { useSidebar } from "../../lib/hooks/use-sidebar";

const Studios = () => {

  const { data: studioList, isError, error } = useGetStudiosQuery();

  const [deleteStudio, deleteStatus] = useDeleteStudioMutation();
  const { setIsOpen } = useSidebar();

  const [updateStudio, updateStatus] = useUpdateStudioMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError && error?.data?.message === "You are not logged in") {
      logout();
    }
  }, [isError, error]);

  const logout = () => {
    localStorage.removeItem('access_token');
    navigate('/login');
  };

  useEffect(() => {
    if (deleteStatus.isSuccess) {
      toast.success("スタジオを削除しました。");
      navigate("/admin/studios");
    }

    if (deleteStatus.isError) {
      if (Array.isArray(deleteStatus.error.data.error)) {
        deleteStatus.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(deleteStatus.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus.isLoading]);

  const updateSchema = object({
    name: string(),
    apikey: string(),
  });

  const methods = useForm({
    resolver: zodResolver(updateSchema),
  });
  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (updateStatus.isSuccess) {
      toast.success("更新しました。");
      // refetch();
      localStorage.removeItem("selectedCompId");
    }

    if (updateStatus.isError) {
      if (Array.isArray(updateStatus.error.data.error)) {
        updateStatus.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(updateStatus.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus.isLoading]);

  useEffect(() => {
    if (studioList && studioList.length > 0) {
      let studio = studioList.find(item => item.id == 1);
      setValue("name", "admin");
      setValue("apikey", studio.apikey);
    }
  }, [studioList]);

  const onSubmitHandler = (values) => {
    setValue("name", "admin");
    updateStudio({ id: 1, studio: values });
  };

  return (
    <main className="flex-grow overflow-auto flex flex-col">
      <div className="flex justify-between items-center mt-4">
        <div className="flex items-center gap-2">
          <button onClick={() => { setIsOpen(true) }} className="p-1 md:hidden ml-2 "><AlignJustifyIcon size={18} /></button>
          <h1 className="text-xl m-3 font-bold">スタジオ一覧</h1>
        </div>
        <button onClick={() => { navigate("/admin/studio/new") }} className="px-4 py-2 bg-blue-600 text-white rounded mr-2">新規登録</button>
      </div>
      {/* <div className="flex p-3 gap-2 items-center">
        <Search size={18} /> <input type="text" 
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="検索" />
      </div> */}
      <table className="m-3 border-collapse border bg-white rounded-lg border-gray-300 overflow-hidden">
        <thead className="bg-gray-50">
          <tr>
            <th className="py-2 px-4 border-b border-gray-300 text-left">スタジオ名</th>
            <th className="py-2 px-4 border-b border-gray-300 text-left">操作</th>
          </tr>
        </thead>
        <tbody>
          { studioList?.length ? studioList && studioList.filter(item => item.id > 1).map(item =>
              <tr key={item.id} className="">
                <td className="py-2 px-4 border-b border-gray-300">{item.name}</td>
                <td className="py-2 px-4 border-b border-gray-300">
                  <button
                    onClick={() => {
                      navigate(`/admin/studio/${item.id}`, {
                        state: { 
                          name: item.name, 
                          apikey: item.apikey 
                        }
                      })
                    }}
                    className="text-indigo-600 hover:text-indigo-900"
                  >
                    <Edit size={18} />
                  </button>
                  <button
                    onClick={() => {
                      if (window.confirm("削除しますか？"))
                        deleteStudio(item.id);
                    }}
                    className="ml-2 text-red-600 hover:text-red-900"
                  >
                    <Trash2 size={18} />
                  </button>
                </td>
              </tr>
            ) :
            <tr>
              <td colSpan="5" className="py-2 px-4 text-center">スタジオなし</td>
            </tr>
          }
        </tbody>
      </table>

      <div className="m-3 p-4 bg-white rounded-lg">
        <h3 className="text-lg font-semibold mb-4">
          社内ドキュメント　OFF
        </h3>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
          >
            <FormInput
              name="apikey"
              type="text"
              placeholder="APIキー"
              className="w-full p-2 mb-2 border border-gray-300 rounded"
            />
            <div className="flex justify-end">
              <button
                className="px-4 py-2 bg-indigo-600 text-white rounded"
                type="submit"
              >
                保存
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </main>
  );
};

export default Studios;
