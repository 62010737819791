import { useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { object, string } from "zod";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";

import { AlignJustifyIcon } from "lucide-react";
import { useSidebar } from "../../lib/hooks/use-sidebar";
import {
  useCreateStudioMutation,
  useUpdateStudioMutation,
  useDeleteStudioMutation,
} from "../../redux/api/studioApi";

import FormInput from "../FormInput";
import { useParams, useNavigate } from "react-router-dom";

const createSchema = object({
  name: string().min(1, "スタジオ名を入力してください。").max(100),
  apikey: string().min(1, "APIキーを入力してください。").max(100),
});

const updateSchema = object({
  name: string().min(1, "スタジオ名を入力してください。").max(100),
  apikey: string().min(1, "APIキーを入力してください。").max(100),
});

const Studio = (props) => {

  const methods = useForm({
    resolver: zodResolver(props.type === "edit" ? updateSchema : createSchema),
  });
  const { setIsOpen } = useSidebar();
  const [createStudio, createStatus] = useCreateStudioMutation();
  const [updateStudio, updateStatus] = useUpdateStudioMutation();
  const [deleteStudio, deleteStatus] = useDeleteStudioMutation();
  const { studioId } = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const { name, apikey } = location.state || {};

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitSuccessful, errors },
  } = methods;

  useEffect(() => {
    if (studioId) {
      setValue("name", name);
      setValue("apikey", apikey);
    } else {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studioId]);

  useEffect(() => {
    if (createStatus.isSuccess) {
      toast.success("スタジオを追加しました。");
      navigate("/admin/studios")
    }

    if (createStatus.isError) {
      if (Array.isArray(createStatus.error.data.error)) {
        createStatus.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(createStatus.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus.isLoading]);

  useEffect(() => {
    if (updateStatus.isSuccess) {
      toast.success("スタジオを更新しました。");
      navigate("/admin/studios")
    }

    if (updateStatus.isError && updateStatus.error.data) {
      if (Array.isArray(updateStatus.error.data.error)) {
        updateStatus.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(updateStatus.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus.isLoading]);

  useEffect(() => {
    if (deleteStatus.isSuccess) {
      toast.success("スタジオを削除しました。");
      navigate("/admin/studios")
    }

    if (deleteStatus.isError && updateStatus.error.data) {
      if (Array.isArray(deleteStatus.error.data.error)) {
        deleteStatus.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(deleteStatus.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus.isLoading]);
  
  useEffect(() => {
    if (isSubmitSuccessful) {
      if (props.type === "add")
      {
        reset();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmitHandler = (values) => {
    switch (props.type) {
      case "add":
        createStudio(values);
        break;
      case "edit":
        updateStudio({ id: studioId, studio: values });
        break;
      default:
    }
  };

  return (
    <main className="flex-grow overflow-auto flex flex-col mb-4">
      <div className="m-3 p-4 bg-white rounded-lg">
        <div className="flex items-center gap-2 mb-4">
          <button onClick={() => { setIsOpen(true) }} className="p-1 md:hidden "><AlignJustifyIcon size={18} /></button>
          <h3 className="text-lg font-semibold ">
            {props.type === "edit" ? "編集" : "新規登録"}
          </h3>
        </div>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
          >
            <FormInput
              name="name"
              type="text"
              placeholder="スタジオ名"
              className="w-full p-2 mb-2 border border-gray-300 rounded"
            />
            <FormInput
              name="apikey"
              type="text"
              placeholder="APIキー"
              className="w-full p-2 mb-2 border border-gray-300 rounded"
            />
            <div className="flex justify-end">
              <button
                className="px-4 py-2 bg-gray-400 text-white rounded mr-2"
                type="button"
                onClick={() => {
                  navigate("/admin/studios")
                }}
              >
                キャンセル
              </button>
              {props.type === "edit" && (
                <button
                  className="px-4 py-2 bg-red-600 text-white rounded mr-2"
                  type="button"
                  onClick={() => {
                    if (window.confirm("削除しますか？"))
                      deleteStudio(studioId);
                  }}
                >
                  削除
                </button>
              )}
              <button
                className="px-4 py-2 bg-indigo-600 text-white rounded"
                type="submit"
              >
                保存
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </main>
  );
};

export default Studio;
