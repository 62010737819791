import { useEffect, useState, useRef } from "react";
import { boolean, object, string } from "zod";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import FormSelect from "../FormSelect";
import { AlignJustifyIcon } from "lucide-react";
import { useSidebar } from "../../lib/hooks/use-sidebar";

import {
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} from "../../redux/api/adminUserApi";

import {
  useGetCompaniesQuery
} from "../../redux/api/companyApi";

import FormInput from "../FormInput";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const createUserSchema = object({
  name: string().min(1, "氏名を入力してください。").max(100),
  email: string()
  .min(1, { message: "メールを入力してください。" })
  .email("有効なメールアドレスを入力してください。")
  .refine(async (e) => {
    // Where checkIfEmailIsValid makes a request to the backend
    // to see if the email is valid.
    if (!e) return false
    return !await checkIfEmailIsValid(e);
  }, "すでに登録されているメールアドレスです。"),
  password: string()
  .min(1, "パスワードを入力してください。")
  .min(8, "パスワードは 8 文字以上である必要があります。")
  .max(32, "パスワードは 32 文字未満にする必要があります。"),
  passwordConfirm: string().min(1, "確認パスワードを入力してください。"),
  role: string().min(1, "権限を選択してください。"),
  company: string()
}).refine((data) => data.password === data.passwordConfirm, {
  path: ["passwordConfirm"],
  message: "パスワードが一致しません。",
});

const updateUserSchema = object({
  name: string().min(1, "氏名を入力してください。").max(100),
  email: string()
    .min(1, "メールを入力してください。")
    .email("有効なメールアドレスを入力してください。")
    .refine(async (e) => {
      return !await checkIfEmailIsValid(e);
    }, "すでに使用されているメールアドレスです。"),
  password: string()
    .optional()
    .refine((value) => {
      if (value === undefined || value === '') {
        return true;
      }
      return value.length >= 8 && value.length <= 32; // Enforce length constraints if password is provided
    }, "パスワードは 8 文字以上かつ 32 文字未満である必要があります。"),
  passwordConfirm: string(),
  role: string().min(1, "権限を選択ください。"),
  company: string()
}).refine((data) => data.password === data.passwordConfirm, {
  path: ["passwordConfirm"],
  message: "パスワードが一致しません。",
});

const checkIfEmailIsValid = async (e) => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_ENDPOINT}/api/admin/user/email`,
    {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: e,
        id: localStorage.getItem("selectedId")
      })
     }
  )
    .then((response) => response.json())
    .then(({data})=> data)
  return response;
}
const User = (props) => {

  const methods = useForm({
    resolver: zodResolver(props.type === "edit" ? updateUserSchema : createUserSchema),
  });
  const user = useSelector((state) => state.userState.user);
  const {data: companyList } = useGetCompaniesQuery();

  const { setIsOpen } = useSidebar();
  const [createUser, createStatus] = useCreateUserMutation();
  const [updateUser, updateStatus] = useUpdateUserMutation();
  const [deleteUser, deleteStatus] = useDeleteUserMutation();
  const [file, setFile] = useState(null);
  const [email, setEmail] = useState(null);
  const fileInputRef = useRef(null);
  const { userId } = useParams();
  const navigate = useNavigate();

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitSuccessful, errors },
  } = methods;

  useEffect(() => {
    if (props.type === "edit" && userId) {
    fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/api/admin/user/${userId}`,
      { credentials: "include" }
    )
      .then((response) => response.json())
      .then(({ data }) => {
        if (data.user_id !== user.id && user.role === 'user') {
          navigate("/chat/free/template/free")
          return
        }
        setValue("name", data.name);
        setValue("email", data.email);
        setValue("role", data.role);
        setValue("company", data.company?.toString() ?? null);
        localStorage.setItem("selectedId", userId)
      });
  } else {
    reset();
    setValue("role", "user");
    localStorage.removeItem("selectedId")
  };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (createStatus.isSuccess) {
      if (file) uploadAvatar("ユーザーを追加しました。")
      else {
        toast.success("ユーザーを追加しました。");
        navigate("/admin/users")
      }
    }

    if (createStatus.isError) {
      if (Array.isArray(createStatus.error.data.error)) {
        createStatus.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(createStatus.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus.isLoading]);

  useEffect(() => {
    if (updateStatus.isSuccess) {
      if (file) uploadAvatar("ユーザー情報を更新しました。")
      else {
        toast.success("ユーザー情報を更新しました。");
        navigate("/admin/users")
      }
    }

    if (updateStatus.isError) {
      if (Array.isArray(updateStatus.error.data.error)) {
        updateStatus.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(updateStatus.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus.isLoading]);

  useEffect(() => {
    if (deleteStatus.isSuccess) {
      toast.success("ユーザーを削除しました。");
      navigate("/admin/users")
    }

    if (deleteStatus.isError) {
      if (Array.isArray(deleteStatus.error.data.error)) {
        deleteStatus.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(deleteStatus.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus.isLoading]);


  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadAvatar = async (message) => {
    if (!email || !file) return;
    const formData = new FormData();
    formData.append('avatar', file);
    formData.append('email', email);

    try {

      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/admin/user/upload-avatar`,
        { credentials: "include", method: "POST", body: formData, headers: { filename: email }, }
      )
        .then((response) => {
          if (!response.ok) {
            console.log('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          toast.success(message);
          setFile(null);
          fileInputRef.current.value = "";
          navigate("/admin/users")
        });
    } catch (error) {
      console.error('Error uploading avatar:', error);
    }
  };

  const roleList = [
    {value: "user", label: "使用者"},
    {value: "admin", label: "管理者"}
  ];
  
  useEffect(() => {
    if (isSubmitSuccessful) {
      if (props.type === "add")
      {
        reset();
        setValue("role", "user");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmitHandler = (values) => {
    setEmail(values.email);
    switch (props.type) {
      case "add":
        createUser(values);
        break;
      case "edit":
        updateUser({ id: userId, user: values });
        break;
      default:
    }
  };


  return (
    <main className="flex-grow overflow-auto flex flex-col mb-4">
      <div className="m-3 p-4 bg-white rounded-lg">
        <div className="flex items-center gap-2 mb-4">
          <button onClick={() => { setIsOpen(true) }} className="p-1 md:hidden "><AlignJustifyIcon size={18} /></button>
          <h3 className="text-lg font-semibold mb-4">
            {props.type === "edit" ? "編集" : "新規登録"}
          </h3>
        </div>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
          >
            <FormInput
              name="name"
              type="text"
              label="氏名"
              className="w-full p-2 mb-2 border border-gray-300 rounded"
            />
            <FormInput
              name="email"
              type="text"
              label="メールアドレス"
              className="w-full p-2 mb-2 border border-gray-300 rounded"
            />
            <FormInput
              name="password"
              type="password"
              placeholder="新しいパスワード"
              className="w-full p-2 mb-2 border border-gray-300 rounded"
            />
            <FormInput
              name="passwordConfirm"
              type="password"
              placeholder="パスワード確認"
              className="w-full p-2 mb-2 border border-gray-300 rounded"
            />            
            <div className="mb-2">
              <label htmlFor="avatar">アバター</label>
              <input
                className="appearance-none rounded-none relative block w-full px-3 py-2 border
                placeholder-gray-500 text-gray-900 rounded-t-md
                  focus:outline-none focus:ring-indigo-500
                focus:border-indigo-500 focus:z-10 sm:text-sm"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                ref={fileInputRef} 
              />
            </div>
            {props.type !== "edit" && (
              <>
                <FormInput
                  name="password"
                  label="パスワード"
                  type="password"
                  className="w-full p-2 mb-2 border border-gray-300 rounded"
                />
                <FormInput
                  name="passwordConfirm"
                  label="パスワード確認"
                  className="w-full p-2 mb-2 border border-gray-300 rounded"
                  type="password"
                />
              </>
            )}
            <FormSelect
              label="権限"
              name="role"
              className="w-full p-2 mb-2 border border-gray-300 rounded outline-none"
              options={roleList}
            />
            <FormSelect
              label="部署"
              name="company"
              className="w-full p-2 mb-2 border border-gray-300 rounded outline-none"
              options={[
                { label: "", value: "" },
                ...(companyList && companyList.length ? companyList.map(c => (
                  { label: c.name, value: c.id }
                )): [])
              ]}
            />
            <div className="flex justify-end">
              <button
                className="px-4 py-2 bg-gray-400 text-white rounded mr-2"
                type="button"
                onClick={() => {
                  navigate("/admin/users")
                }}
              >
                キャンセル
              </button>
              {props.type === "edit" && (
                <button
                  className="px-4 py-2 bg-red-600 text-white rounded mr-2"
                  type="button"
                  onClick={() => {
                    if (window.confirm("削除しますか？"))
                      deleteUser(userId);
                  }}
                >
                  削除
                </button>
              )}
              <button
                className="px-4 py-2 bg-indigo-600 text-white rounded"
                type="submit"
              >
                保存
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </main>
  );
};

export default User;
