import { configureStore } from "@reduxjs/toolkit";
import { authApi } from "./api/authApi";
import { templateApi } from "./api/templateApi";
import { knowledgeApi } from "./api/knowledgeApi";
import { studioApi } from "./api/studioApi";
import { userApi } from "./api/userApi";
import { chatApi } from "./api/chatApi";
import { adminUserApi } from "./api/adminUserApi";
import { companyApi } from "./api/companyApi";
import userReducer from "./features/userSlice";

export default configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [templateApi.reducerPath]: templateApi.reducer,
    [knowledgeApi.reducerPath]: knowledgeApi.reducer,
    [studioApi.reducerPath]: studioApi.reducer,
    [chatApi.reducerPath]: chatApi.reducer,
    [adminUserApi.reducerPath]: adminUserApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    userState: userReducer,
  },
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      authApi.middleware,
      userApi.middleware,
      templateApi.middleware,
      knowledgeApi.middleware,
      studioApi.middleware,
      chatApi.middleware,
      adminUserApi.middleware,
      companyApi.middleware,
    ]),
});
