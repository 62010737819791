import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const UpdateAvatar = () => {
  const user = useSelector((state) => state.userState.user);

  const [file, setFile] = useState(null);
  const [email, setEmail] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadAvatar = async () => {
    // if (!email || !file) return;
    if ( !file) return;
    const formData = new FormData();
    formData.append('avatar', file);
    // formData.append('email', email);

    try {

      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/admin/user/upload-avatar`,
        { credentials: "include", method: "POST", body: formData, headers: { filename: user.id }, }
      )
        .then((response) => {
          if (!response.ok) {
            console.log('Network response was not ok');
            toast.error("アイコンの変更に失敗しました。"); // Change the message as needed
            throw new Error('Network response was not ok'); // Throw an error to skip the next then
          }            
          return response.json();
        })
        .then((data) => {
          // console.log(data)
          toast.success("アイコンを変更しました。");
          setFile(null);
          fileInputRef.current.value = "";
          setTimeout(() => {
            window.location.reload();
          }, 1000)          
        })
        .catch ((e) => {
        });
    } catch (error) {
      console.error('Error uploading avatar:', error);
    }
  };
  
  return (
    <div className="m-3 p-4 bg-white rounded-lg">
      <div className="mb-2">
        <h3 className="text-lg font-semibold mb-4">
          アバター変更
        </h3>
        <input
          className="appearance-none rounded-none relative block w-full px-3 py-2 border
          placeholder-gray-500 text-gray-900 rounded-t-md
            focus:outline-none focus:ring-indigo-500
          focus:border-indigo-500 focus:z-10 sm:text-sm"
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          ref={fileInputRef} 
        />
      </div>
      <div className="flex justify-end">
        <button
          className="px-4 py-2 bg-indigo-600 text-white rounded"
          onClick={uploadAvatar}
          // type="submit"
        >
          保存
        </button>
      </div>
    </div>
  );
};

export default UpdateAvatar;
