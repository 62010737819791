import { useEffect, useState } from "react";
import { object, string } from "zod";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import FormSelect from "./FormSelect";
import { AlignJustifyIcon } from "lucide-react";
import { useSidebar } from "../lib/hooks/use-sidebar";

import {
  useCreateTemplateMutation,
  useDeleteTemplateMutation,
  useUpdateTemplateMutation,
} from "../redux/api/templateApi";
import {
  useGetCompaniesQuery
} from "../redux/api/companyApi";

import FormInput from "./FormInput";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const createTemplateSchema = (role, publicValue) => {
  const baseSchema = {
    title: string().min(1, "テンプレート名を入力してください。"),
    prompt: string().min(1, "プロンプトを入力してください。"),
    public: string(),
  };

  if (role === 'admin' && publicValue === '1') {
    baseSchema.company = string().optional();
  }

  return object(baseSchema);
};

const Template = (props) => {
  const user = useSelector((state) => state.userState.user);
  const {data: companyList } = useGetCompaniesQuery();

  const { setIsOpen } = useSidebar();
  const [createTemplate, createStatus] = useCreateTemplateMutation();
  const [updateTemplate, updateStatus] = useUpdateTemplateMutation();
  const [deleteTemplate, deleteStatus] = useDeleteTemplateMutation();

  const { templateId } = useParams();
  const navigate = useNavigate();
  const [publicValue, setPublicValue] = useState("");

  const templateSchema = createTemplateSchema(user.role, publicValue);
  const methods = useForm({
    resolver: zodResolver(templateSchema),
  });

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitSuccessful, errors },
  } = methods;

  const handlePublicChange = (event) => {
    setPublicValue(event.target.value);
    setValue("public", event.target.value);
  };

  useEffect(() => {
    if (props.type === "edit" && templateId) {
      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/template/${templateId}`,
        { credentials: "include" }
      )
        .then((response) => response.json())
        .then(({ data }) => {
          if (data.user_id !== user.id && user.role === 'user') {
            navigate(`/chat/free/template/${templateId}`)
            return
          }
          setValue("title", data.title);
          setValue("prompt", data.prompt);
          setValue("public", data.public);
          setValue("company", data.company);
          setValue("created_user", data.created_user);
          setPublicValue(data.public);
          const createdAt = new Date(data.created_at);

          const year = createdAt.getFullYear();
          const month = String(createdAt.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
          const day = String(createdAt.getDate()).padStart(2, '0');
          const hours = String(createdAt.getHours()).padStart(2, '0');
          const minutes = String(createdAt.getMinutes()).padStart(2, '0');

          const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;

          setValue("created_at", formattedDate);          
        });
    } else {
      reset();
      setValue("public", "0");
      setPublicValue("0");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId]);

  useEffect(() => {
    if (createStatus.isSuccess) {
      toast.success("テンプレートを追加しました。");
    }

    if (createStatus.isError) {
      if (Array.isArray(createStatus.error.data.error)) {
        createStatus.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(createStatus.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus.isLoading]);

  useEffect(() => {
    if (updateStatus.isSuccess) {
      toast.success("テンプレートを更新しました。");
    }

    if (updateStatus.isError) {
      if (Array.isArray(updateStatus.error.data.error)) {
        updateStatus.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(updateStatus.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus.isLoading]);

  useEffect(() => {
    if (deleteStatus.isSuccess) {
      toast.success("テンプレートを削除しました。");
      navigate("/chat/free/template/free");
    }

    if (deleteStatus.isError) {
      if (Array.isArray(deleteStatus.error.data.error)) {
        deleteStatus.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(deleteStatus.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus.isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      if (props.type === "add") {
        reset();
        setValue("public", "0");
        setPublicValue("0");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmitHandler = (values) => {
    switch (props.type) {
      case "add":
        createTemplate(values);
        break;
      case "edit":
        updateTemplate({ id: templateId, template: values });
        break;
      default:
    }
  };

  return (
    <main className="flex-grow overflow-hidden flex flex-col">
      <div className="p-4 bg-white">
        <div className="flex items-center gap-2 mb-4">
          <button onClick={() => { setIsOpen(true) }} className="p-1 md:hidden "><AlignJustifyIcon size={18} /></button>
          <h3 className="text-lg font-semibold">
            {props.type === "edit" ? "テンプレートの編集" : "新しいテンプレート"}
          </h3>
        </div>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
          >
            <FormInput
              name="title"
              type="text"
              placeholder="テンプレート名"
              className="w-full p-2 mb-2 border border-gray-300 rounded"
            />
            <div className="mb-2">
              <textarea
                {...register("prompt")}
                placeholder="プロンプト"
                className="w-full p-2 mb-2 border border-gray-300 rounded"
                rows={3}
              />
              <p className="text-red-600">
                {errors["prompt"] ? errors["prompt"].message : ""}
              </p>
            </div>
            <div className="flex justify-between mb-2">
              <div className="flex gap-2 items-center">
                <div className="mb-2">公開</div>
                <FormSelect
                  name="public"
                  className="w-full p-2 border border-gray-300 rounded outline-none"
                  options={[
                    { label: "非公開", value: "0" },
                    { label: "部署に公開", value: "1" },
                    { label: "公開", value: "2" },
                  ]}
                  onChange={handlePublicChange}
                />
                { user.role === 'admin' && publicValue == '1' &&
                  <>
                    <div className="mb-2">部署</div>
                    <FormSelect
                      name="company"
                      className="w-full p-2 border border-gray-300 rounded outline-none"
                      options={[
                        ...(companyList && companyList.length ? companyList.map(c => (
                          { label: c.name, value: c.id }
                        )): [])
                      ]}
                    />
                  </>
                }
              </div>
              <div>
                {props.type === "edit" && (
                  <button
                    className="px-4 py-2 bg-red-600 text-white rounded mr-2"
                    type="button"
                    onClick={() => {
                      if (window.confirm("削除しますか？"))
                        deleteTemplate(templateId);
                    }}
                  >
                    削除
                  </button>
                )}
                <button
                  className="px-4 py-2 bg-indigo-600 text-white rounded"
                  type="submit"
                >
                  保存
                </button>
              </div>              
            </div>
            { props.type === "edit" &&
              <div className="flex gap-2 items-center">
                <div className="mb-2">作成者</div>
                <FormInput
                  name="created_user"
                  type="text"
                  className="w-full p-2 mb-2 border border-gray-300 rounded"
                  readOnly={true}
                />
                <div className="mb-2">作成時間</div>
                <FormInput
                  name="created_at"
                  type="text"
                  className="w-full p-2 mb-2 border border-gray-300 rounded"
                  readOnly={true}
                /> 
              </div>
            }
          </form>
        </FormProvider>
      </div>
    </main>
  );
};

export default Template;
