import { useEffect, useState } from "react";
import { LogOut, Plus, Edit, MessageSquare, KeyIcon, Trash2, ChevronRight } from "lucide-react";
import { useParams, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import { useLogoutUserMutation } from "../../redux/api/authApi";
import { useGetChatsQuery } from "../../redux/api/chatApi";
import {
  useDeleteTemplateMutation,
  useGetTemplatesQuery
} from "../../redux/api/templateApi";
import { useGetStudiosQuery } from "../../redux/api/studioApi";
import { useSidebar } from "../../lib/hooks/use-sidebar";
import { Accordion } from "../accordion";

const UserSidebar = ({ isDifyMode, setIsDifyMode, studio, setStudio }) => {
  const user = useSelector((state) => state.userState.user);
  const cookie = useCookies(["logged_in"]);
  const removeCookies = cookie[2];
  const templateInfo = useGetTemplatesQuery();
  const { data: studioList, isError, error } = useGetStudiosQuery();

  const { isOpen } = useSidebar();

  const chatInfo = useGetChatsQuery();
  const [logout, logoutStatus] = useLogoutUserMutation();
  const [deleteTemplate, deleteStatus] = useDeleteTemplateMutation();

  const { templateId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (logoutStatus.isSuccess) {
      toast.success("ログアウトされました");
      removeCookies("logged_in")
      window.location.href = "/login";
    }

    if (logoutStatus.isError) {
      if (Array.isArray(logoutStatus.error.data.error)) {
        logoutStatus.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(logoutStatus.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutStatus.isLoading]);

  useEffect(() => {
    if (deleteStatus.isSuccess) {
      toast.success("テンプレートを削除しました。");
      navigate("/template");
    }

    if (deleteStatus.isError) {
      if (Array.isArray(deleteStatus.error.data.error)) {
        deleteStatus.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(deleteStatus.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus.isLoading]);

  useEffect(() => {
    if (studioList && studioList.length > 0) {      
      let studio = studioList.find(item => item.id > 1);
      if (studio)
        setStudio(studio.id);
    }
  }, [studioList]);

  const formattedTime = ((time) => {
    const date = new Date(time);
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
  });  

  return (
    <div className={`bg-indigo-800 overflow-hidden text-white py-6 flex flex-col transition-all duration-300 md:w-80 md:px-5 ${isOpen ? "w-[100vw] px-5" : "w-0 px-0"}`}>
      <div className="flex justify-end gap-4 mb-4">
        <h1 className="text-sm font-semibold mb-2 mt-1">社内ドキュメント</h1>
        <button
          onClick={() => { setIsDifyMode(!isDifyMode); navigate("/"); }}
          className={`flex items-center justify-between p-2 rounded-full transition-colors duration-300 
            ${isDifyMode ? 'bg-green-500' : 'bg-yellow-300'} min-w-[50px]`} // Set a minimum width
        >
          <span
            className={`w-4 h-4 rounded-full transition-transform duration-300 
              ${isDifyMode ? 'transform translate-x-4 bg-white' : 'bg-white'}`}
          />
        </button>
      </div>
      { isDifyMode &&
          <select
            className="bg-gray-50 text-white-900 text-sm rounded bg-indigo-600 hover:bg-indigo-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white mb-2"
            value={studio}
            onChange={(event) => setStudio(event.target.value)}
          >
            { studioList && studioList.filter(item => item.id > 1).map(item =>
              <option key={item.id} value={item.id}>{item.name}</option>
            )}
          </select>
      }
      <button
        className="flex items-center w-full py-2 px-4 rounded mb-4 bg-indigo-600 hover:bg-indigo-500 transition-colors duration-200"
        onClick={() => {
          navigate("/chat/free/template/free");
        }}
      >
        <Plus size={18} className="mr-2" /> 新しいチャット
      </button>
      <div className="flex-grow flex flex-col overflow-y-scroll overflow-x-hidden scrollbar px-1">
        {!isDifyMode &&
          <Accordion defaultState>
            <div className="flex justify-between items-center w-full">
              <h2 className="text-sm font-semibold mb-2">テンプレート</h2>
              <button
                onClick={() => navigate("template/new")}
                className="text-indigo-300 hover:text-white mr-2"
                disabled={isDifyMode}
              >
                <Plus size={18} />
              </button>
            </div>
            <div>
              <span className="pl-4 text-sm">非公開</span>
              <div className="flex flex-col gap-2 mt-1 mb-2">
                {templateInfo.data &&
                  templateInfo.data.filter(t => t.public === "0").map((template) => (
                    <div
                      key={template.id}
                      className="flex items-center pl-4"
                    >
                      <button
                        className={`text-xs flex flex-1 py-2 px-2 rounded-md transition-colors duration-200 ${
                          template.id.toString() !== templateId
                            ? "bg-indigo-600 text-white hover:bg-indigo-500"
                            : "bg-indigo-500 text-white"
                        }`}
                        onClick={() =>
                          navigate(`/chat/free/template/${template.id}`)
                        }
                      >
                        <Plus size={18} className="mr-2" />
                        {template.title}
                      </button>
                      {
                        template.user_id === user.id || user.role === 'admin' ?
                        <>
                          <button
                            className="ml-1 text-indigo-300 hover:text-white"
                            onClick={() => navigate(`/template/edit/${template.id}`)}
                          >
                            <Edit size={12} />
                          </button>
                          <button
                            className="ml-1 text-indigo-300 hover:text-white"
                            onClick={() => {
                              if (window.confirm("削除しますか？"))
                                deleteTemplate(template.id);
                            }}
                          >
                            <Trash2 size={12} />
                          </button>
                        </>
                        :
                        <div className="opacity-0">
                          <button
                            className="ml-1 cursor-default"
                          >
                            <Edit size={12} />
                          </button>
                          <button
                            className="ml-1 cursor-default"
                          >
                            <Trash2 size={12} />
                          </button>
                        </div>
                      }
                    </div>
                  ))}
              </div>
              <span className="pl-4 text-sm">部署に公開</span>
              <div className="flex flex-col gap-2 mt-1 mb-2">
                {templateInfo.data &&
                  templateInfo.data.filter(t => t.public !== "0" && t.public !== "2").map((template) => (
                    <div
                      key={template.id}
                      className="flex items-center pl-4"
                    >
                      <button
                        className={`text-xs flex flex-1 py-2 px-2 rounded-md transition-colors duration-200 ${
                          template.id.toString() !== templateId
                            ? "bg-indigo-600 text-white hover:bg-indigo-500"
                            : "bg-indigo-500 text-white"
                        }`}
                        onClick={() =>
                          navigate(`/chat/free/template/${template.id}`)
                        }
                      >
                        <Plus size={18} className="mr-2" />
                        {template.title}
                      </button>
                      {
                        template.user_id === user.id || user.role === 'admin' ?
                        <>
                          <button
                            className="ml-1 text-indigo-300 hover:text-white"
                            onClick={() => navigate(`/template/edit/${template.id}`)}
                          >
                            <Edit size={12} />
                          </button>
                          <button
                            className="ml-1 text-indigo-300 hover:text-white"
                            onClick={() => {
                              if (window.confirm("削除しますか？"))
                                deleteTemplate(template.id);
                            }}
                          >
                            <Trash2 size={12} />
                          </button>
                        </>
                        :
                        <div className="opacity-0">
                          <button
                            className="ml-1 cursor-default"
                          >
                            <Edit size={12} />
                          </button>
                          <button
                            className="ml-1 cursor-default"
                          >
                            <Trash2 size={12} />
                          </button>
                        </div>
                      }
                    </div>
                  ))}
              </div>              
              <span className="pl-4 text-sm">公開</span>
              <div className="flex flex-col gap-2 mt-1 mb-2">
                {templateInfo.data &&
                  templateInfo.data.filter(t => t.public === "2").map((template) => (
                    <div
                      key={template.id}
                      className="flex items-center pl-4"
                    >
                      <button
                        className={`text-xs flex flex-1 py-2 px-2 rounded-md transition-colors duration-200 ${
                          template.id.toString() !== templateId
                            ? "bg-indigo-600 text-white hover:bg-indigo-500"
                            : "bg-indigo-500 text-white"
                        }`}
                        onClick={() =>
                          navigate(`/chat/free/template/${template.id}`)
                        }
                      >
                        <Plus size={18} className="mr-2" />
                        {template.title}
                      </button>
                      {
                        template.user_id === user.id || user.role === 'admin' ?
                        <>
                          <button
                            className="ml-1 text-indigo-300 hover:text-white"
                            onClick={() => navigate(`/template/edit/${template.id}`)}
                          >
                            <Edit size={12} />
                          </button>
                          <button
                            className="ml-1 text-indigo-300 hover:text-white"
                            onClick={() => {
                              if (window.confirm("削除しますか？"))
                                deleteTemplate(template.id);
                            }}
                          >
                            <Trash2 size={12} />
                          </button>
                        </>
                        :
                        <div className="opacity-0">
                          <button
                            className="ml-1 cursor-default"
                          >
                            <Edit size={12} />
                          </button>
                          <button
                            className="ml-1 cursor-default"
                          >
                            <Trash2 size={12} />
                          </button>
                        </div>
                      }
                    </div>
                  ))}
              </div>
            </div>
          </Accordion>
        }
        <Accordion defaultState>
          <span>チャット履歴</span>
          <div className="flex-grow overflow-y-auto scrollbar overflow-x-hidden">
            {chatInfo.data &&
              chatInfo.data.filter(item => item.is_company == isDifyMode).map((chat) => (
                <button
                  key={`chat-history-item-${chat.id}`}
                  onClick={() =>
                    navigate(
                      `/chat/${chat.id}/template/${
                        chat.template_id === null ? "free" : chat.template_id
                      }`
                    )
                  }
                  className={`flex flex-col items-start w-full py-2 px-4 rounded mb-2 ${
                    /*currentChatId === chat.id
                      ? "bg-indigo-600"
                      : */ "hover:bg-indigo-700"
                  }`}
                >
                  <div className="flex items-center">
                    <MessageSquare size={18} className="mr-2 flex-shrink-0" />
                    <span className="truncate mr-2">
                      {JSON.parse(chat.messages)[0].content}
                    </span>
                    <span className="truncate text-xs text-gray-400">
                      {formattedTime(JSON.parse(chat.messages)[0].time)}
                    </span>
                  </div>
                </button>
              ))}
          </div>
        </Accordion>
        <div className="flex items-center pb-4 border-t border-indigo-700 mt-4 pt-4">
          <img
            src={user.avatar ? `${process.env.REACT_APP_SERVER_ENDPOINT}${user.avatar}` : "/assets/user.png"}
            alt="User"
            className="w-8 h-8 rounded-full mr-3"
          />
          <span className="font-medium">{user.name}</span>
        </div>
        <button
          className="flex items-center w-full py-2 px-4 rounded hover:bg-indigo-700"
          onClick={() => navigate('/password')}
        >
          <KeyIcon className="mr-3" size={18} />
          アカウント情報
        </button>

        <button
          className="flex items-center w-full py-2 px-4 rounded hover:bg-indigo-700"
          onClick={() => logout()}
        >
          <LogOut className="mr-3" size={18} />
          ログアウト
        </button>
      </div>
    </div>
  );
};

export default UserSidebar;
