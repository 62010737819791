import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { object, string } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link, useNavigate } from "react-router-dom";
import { useRegisterUserMutation } from "../redux/api/authApi";
import { toast } from "react-toastify";
import FormInput from "../components/FormInput";

const registerSchema = object({
  name: string().min(1, "氏名を入力してください。").max(100),
  email: string()
    .min(1, "メールアドレスを入力してください。")
    .email("無効なメールアドレスです。"),
  password: string()
    .min(1, "パスワードを入力してください。")
    .min(8, "パスワードは8文字以上入力してください。")
    .max(32, "パスワードは32文字以下で入力してください"),
  passwordConfirm: string().min(1, "確認パスワードを入力してください。"),
}).refine((data) => data.password === data.passwordConfirm, {
  path: ["passwordConfirm"],
  message: "パスワードが一致しません。",
});

const RegisterPage = () => {
  const methods = useForm({
    resolver: zodResolver(registerSchema),
  });

  const [registerUser, { isLoading, isSuccess, error, isError }] =
    useRegisterUserMutation();

  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      toast.success("登録されました");
      navigate("/login");
    }

    if (isError) {
      console.log(error);
      if (Array.isArray(error.data.error)) {
        error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmitHandler = (values) => {
    registerUser(values);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            AIMentorに新規登録
          </h2>
        </div>
        <FormProvider {...methods}>
          <form
            className="mt-8 space-y-6"
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
          >
            <div className="rounded-md shadow-sm">
              <FormInput name="name" label="ユーザー名" />
              <FormInput name="email" label="メールアドレス" type="email" />
              <FormInput name="password" label="パスワード" type="password" />
              <FormInput
                name="passwordConfirm"
                label="パスワード確認"
                type="password"
              />
              <p className="text-sm my-4">
                既にアカウントがありましたら{" "}
                <Link
                  className="no-underline text-['#2363eb'] hover:underline"
                  to="/login"
                >
                  こちら
                </Link>
              </p>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                新規登録
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default RegisterPage;
