import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT;

export const templateApi = createApi({
  reducerPath: "templateApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/template/`,
  }),
  tagTypes: ["Templates"],
  endpoints: (builder) => ({
    createTemplate: builder.mutation({
      query(data) {
        return {
          url: "",
          method: "POST",
          credentials: "include",
          body: data,
        };
      },
      invalidatesTags: [{ type: "Templates", id: "LIST" }],
    }),
    getTemplates: builder.query({
      query() {
        return {
          url: "",
          credentials: "include",
        };
      },
      transformResponse: (result) => result.data.templates,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Templates", id })),
              { type: "Templates", id: "LIST" },
            ]
          : [{ type: "Templates", id: "LIST" }],
    }),
    updateTemplate: builder.mutation({
      query({ id, template }) {
        return {
          url: `${id}`,
          method: "PATCH",
          credentials: "include",
          body: template,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "Templates", id },
              { type: "Templates", id: "LIST" },
            ]
          : [{ type: "Templates", id: "LIST" }],
    }),
    deleteTemplate: builder.mutation({
      query(id) {
        return {
          url: `${id}`,
          method: "DELETE",
          credentials: "include",
        };
      },
      invalidatesTags: [{ type: "Templates", id: "LIST" }],
    }),
  }),
});

export const {
  useCreateTemplateMutation,
  useGetTemplatesQuery,
  useUpdateTemplateMutation,
  useDeleteTemplateMutation,
} = templateApi;
