import { useEffect } from "react";
import { LogOut, KeyIcon, Users, Building, Plus, Edit, Trash2 } from "lucide-react";
import { useParams, useNavigate, Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import {
  useGetTemplatesQuery,
  useDeleteTemplateMutation
} from "../../redux/api/templateApi";
import { useSelector } from "react-redux";
import { useLogoutUserMutation } from "../../redux/api/authApi";
import { Accordion } from "../accordion";
import { useSidebar } from "../../lib/hooks/use-sidebar";

const AdminSidebar = () => {
  const user = useSelector((state) => state.userState.user);

  const cookie = useCookies(["logged_in"]);
  const removeCookies = cookie[2];

  const templateInfo = useGetTemplatesQuery();
  const [logout, logoutStatus] = useLogoutUserMutation();
  const [deleteTemplate] = useDeleteTemplateMutation();

  const { isOpen } = useSidebar();
  const { templateId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (logoutStatus.isSuccess) {
      toast.success("ログアウトされました");
      removeCookies("logged_in")
      window.location.href = "/login";
    }

    if (logoutStatus.isError) {
      if (Array.isArray(logoutStatus.error.data.error)) {
        logoutStatus.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(logoutStatus.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutStatus.isLoading]);

  return (
    <div className={`bg-indigo-800 overflow-hidden text-white py-6 flex flex-col transition-all duration-300 md:w-80 md:px-5 ${isOpen ? "w-[100vw] px-5" : "w-0 px-0"}`}>
      {/* 新しいチャットボタン */}
      <h2 className="flex items-center w-full md:ml-0 ml-6 pb-2 px-4 rounded mb-4 text-lg font-bold">
        AIMentor 管理者
      </h2>
      <div className="flex-grow flex flex-col px-1 overflow-y-scroll overflow-x-hidden scrollbar">
        <div className="mb-8">
          <div className="flex flex-col gap-2 mb-4">
            <NavLink
              to='/admin/users' 
              className={({ isActive }) => `flex items-center ${isActive ? 'font-bold' : ''}`}
            >
              <Users size={18} className="mr-2" /> ユーザー
            </NavLink>
            <NavLink 
              to='/admin/company' 
              className={({ isActive }) => `flex items-center ${isActive ? 'font-bold' : ''}`}
            >            
              <Building size={18} className="mr-2" /> 部署
            </NavLink>
            <NavLink to='/admin/studios'
              className={({ isActive }) => `flex items-center ${isActive ? 'font-bold' : ''}`}
            >
              <Users size={18} className="mr-2" /> スタジオ
            </NavLink>
            <NavLink to='/admin/knowledges'
              className={({ isActive }) => `flex items-center ${isActive ? 'font-bold' : ''}`}
            >
              <Users size={18} className="mr-2" /> ナレッジ
            </NavLink>
          </div>

          <Accordion defaultState>
            <div className="flex justify-between items-center w-full">
              <h2 className="text-sm font-semibold mb-2">テンプレート</h2>
              <button
                onClick={() => navigate("admin/template/new")}
                className="text-indigo-300 hover:text-white mr-2"
              >
                <Plus size={18} />
              </button>
            </div>
            <div>
              <span className="pl-4 text-sm">非公開</span>
              <div className="flex flex-col gap-2 mt-1 mb-2">
                {templateInfo.data &&
                  templateInfo.data.filter(t => t.public === "0").map((template) => (
                    <div
                      key={template.id}
                      className="flex items-center pl-4"
                    >
                      <button
                        className={`text-xs flex flex-1 py-2 px-2 rounded-md transition-colors duration-200 ${
                          template.id.toString() !== templateId
                            ? "bg-indigo-600 text-white hover:bg-indigo-500"
                            : "bg-indigo-500 text-white"
                        }`}
                        onClick={() =>
                          navigate(`/admin/template/edit/${template.id}`)
                        }
                      >
                        <Plus size={18} className="mr-2" />
                        {template.title}
                      </button>
                      {
                        template.user_id === user.id || user.role === 'admin' ?
                        <>
                          <button
                            className="ml-1 text-indigo-300 hover:text-white"
                            onClick={() => navigate(`/admin/template/edit/${template.id}`)}
                          >
                            <Edit size={12} />
                          </button>
                          <button
                            className="ml-1 text-indigo-300 hover:text-white"
                            onClick={() => {
                              if (window.confirm("削除しますか？"))
                                deleteTemplate(template.id);
                            }}
                          >
                            <Trash2 size={12} />
                          </button>
                        </>
                        :
                        <div className="opacity-0">
                          <button
                            className="ml-1 cursor-default"
                          >
                            <Edit size={12} />
                          </button>
                          <button
                            className="ml-1 cursor-default"
                          >
                            <Trash2 size={12} />
                          </button>
                        </div>
                      }
                    </div>
                  ))}
              </div>
              <span className="pl-4 text-sm">部署に公開</span>
              <div className="flex flex-col gap-2 mt-1 mb-2">
                {templateInfo.data &&
                  templateInfo.data.filter(t => t.public !== "0" && t.public !== "2").map((template) => (
                    <div
                      key={template.id}
                      className="flex items-center pl-4"
                    >
                      <button
                        className={`text-xs flex flex-1 py-2 px-2 rounded-md transition-colors duration-200 ${
                          template.id.toString() !== templateId
                            ? "bg-indigo-600 text-white hover:bg-indigo-500"
                            : "bg-indigo-500 text-white"
                        }`}
                        onClick={() =>
                          navigate(`/admin/template/edit/${template.id}`)
                        }
                      >
                        <Plus size={18} className="mr-2" />
                        {template.title}
                      </button>
                      {
                        template.user_id === user.id || user.role === 'admin' ?
                        <>
                          <button
                            className="ml-1 text-indigo-300 hover:text-white"
                            onClick={() => navigate(`/admin/template/edit/${template.id}`)}
                          >
                            <Edit size={12} />
                          </button>
                          <button
                            className="ml-1 text-indigo-300 hover:text-white"
                            onClick={() => {
                              if (window.confirm("削除しますか？"))
                                deleteTemplate(template.id);
                            }}
                          >
                            <Trash2 size={12} />
                          </button>
                        </>
                        :
                        <div className="opacity-0">
                          <button
                            className="ml-1 cursor-default"
                          >
                            <Edit size={12} />
                          </button>
                          <button
                            className="ml-1 cursor-default"
                          >
                            <Trash2 size={12} />
                          </button>
                        </div>
                      }
                    </div>
                  ))}
              </div>
              <span  className="pl-4 text-sm">公開</span>
              <div className="flex flex-col gap-2 mt-1 mb-2">
                {templateInfo.data &&
                  templateInfo.data.filter(t => t.public === "2").map((template) => (
                    <div
                      key={template.id}
                      className="flex items-center pl-4"
                    >
                      <button
                        className={`text-xs flex flex-1 py-2 px-2 rounded-md transition-colors duration-200 ${
                          template.id.toString() !== templateId
                            ? "bg-indigo-600 text-white hover:bg-indigo-500"
                            : "bg-indigo-500 text-white"
                        }`}
                        onClick={() =>
                          navigate(`/admin/template/edit/${template.id}`)
                        }
                      >
                        <Plus size={18} className="mr-2" />
                        {template.title}
                      </button>
                      {
                        template.user_id === user.id || user.role === 'admin' ?
                        <>
                          <button
                            className="ml-1 text-indigo-300 hover:text-white"
                            onClick={() => navigate(`/admin/template/edit/${template.id}`)}
                          >
                            <Edit size={12} />
                          </button>
                          <button
                            className="ml-1 text-indigo-300 hover:text-white"
                            onClick={() => {
                              if (window.confirm("削除しますか？"))
                                deleteTemplate(template.id);
                            }}
                          >
                            <Trash2 size={12} />
                          </button>
                        </>
                        :
                        <div className="opacity-0">
                          <button
                            className="ml-1 cursor-default"
                          >
                            <Edit size={12} />
                          </button>
                          <button
                            className="ml-1 cursor-default"
                          >
                            <Trash2 size={12} />
                          </button>
                        </div>
                      }
                    </div>
                  ))}
              </div>
            </div>
          </Accordion>
        </div>
        <div className="flex items-center pb-4 border-t border-indigo-700 pt-4">
          <img
            src={user.avatar ? `${process.env.REACT_APP_SERVER_ENDPOINT}${user.avatar}` : "/assets/user.png"}
            alt="User"
            className="w-8 h-8 rounded-full mr-3"
          />
          <span className="font-medium">{user.name}</span>
        </div>
        <button
          className="flex items-center w-full py-2 px-4 rounded hover:bg-indigo-700"
          onClick={() => navigate('/admin/password')}
        >
          <KeyIcon className="mr-3" size={18} />
          アカウント情報
        </button>

        <button
          className="flex items-center w-full py-2 px-4 rounded hover:bg-indigo-700"
          onClick={() => logout()}
        >
          <LogOut className="mr-3" size={18} />
          ログアウト
        </button>
      </div>
    </div>
  );
};

export default AdminSidebar;
