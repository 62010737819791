import { useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { object, string } from "zod";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";

import { AlignJustifyIcon } from "lucide-react";
import { useSidebar } from "../../lib/hooks/use-sidebar";
import {
  useCreateKnowledgeMutation,
  useUpdateKnowledgeMutation,
  useDeleteKnowledgeMutation,
} from "../../redux/api/knowledgeApi";

import FormInput from "../FormInput";
import { useParams, useNavigate } from "react-router-dom";

const createSchema = object({
  title: string().min(1, "ナレッジ名を入力してください。").max(100),
  content: string(),
});

const updateSchema = object({
  title: string().min(1, "ナレッジ名を入力してください。").max(100),
});

const Knowledge = (props) => {

  const methods = useForm({
    resolver: zodResolver(props.type === "edit" ? updateSchema : createSchema),
  });
  const { setIsOpen } = useSidebar();
  const [createKnowledge, createStatus] = useCreateKnowledgeMutation();
  const [updateKnowledge, updateStatus] = useUpdateKnowledgeMutation();
  const [deleteKnowledge, deleteStatus] = useDeleteKnowledgeMutation();
  const [file, setFile] = useState(null);
  const [documents, setDocuments] = useState([]);
  const fileInputRef = useRef(null);
  const { knowledgeId } = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const { title, content } = location.state || {};

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitSuccessful, errors },
  } = methods;

  useEffect(() => {
    if (props.type === "edit" && knowledgeId) {
      setValue("title", title);
      setValue("content", content);

      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/admin/knowledge/${knowledgeId}/documents`,
        { credentials: "include" }
      )
        .then((response) => response.json())
        .then(({ data }) => {
          setDocuments(data);
        });      
    } else {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [knowledgeId]);

  useEffect(() => {
    if (createStatus.isSuccess) {
      if (file) uploadFile("ナレッジを追加しました。")
      else {
        toast.success("ナレッジを追加しました。");
        navigate("/admin/knowledges")
      }
    }

    if (createStatus.isError) {
      if (Array.isArray(createStatus.error.data.error)) {
        createStatus.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(createStatus.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus.isLoading]);

  useEffect(() => {
    if (updateStatus.isSuccess) {
      if (file) uploadFile("ナレッジを更新しました。")
      else {
        toast.success("ナレッジを更新しました。");
        navigate("/admin/knowledges")
      }
    }

    if (updateStatus.isError) {
      if (Array.isArray(updateStatus.error.data.error)) {
        updateStatus.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(updateStatus.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus.isLoading]);

  useEffect(() => {
    if (deleteStatus.isSuccess) {
      toast.success("ナレッジを削除しました。");
      navigate("/admin/knowledges")
    }

    if (deleteStatus.isError) {
      if (Array.isArray(deleteStatus.error.data.error)) {
        deleteStatus.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(deleteStatus.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus.isLoading]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const maxSizeInBytes = 15 * 1024 * 1024; // 15MB
      if (file.size > maxSizeInBytes) {
        alert("1つあたりの最大サイズは15MBです。");
        e.target.value = null;
        return;
      }
      setFile(e.target.files[0]);
    }    
  };

  const uploadFile = async (message) => {
    if (!file) return;

    const formData = new FormData();
    formData.append('id', knowledgeId);
    formData.append('document', file);
    const filename = file.name.replace(/\.[^/.]+$/, "")

    try {

      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/admin/knowledge/upload`,
        { credentials: "include", method: "POST", body: formData, headers: { filename: filename }, }
      )
        .then((response) => {
          if (!response.ok) {
            console.log('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          toast.success(message);
          setFile(null);
          fileInputRef.current.value = "";
          navigate("/admin/knowledges")
        });
    } catch (error) {
      console.error('Error uploading avatar:', error);
    }
  };
  
  useEffect(() => {
    if (isSubmitSuccessful) {
      if (props.type === "add")
      {
        reset();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmitHandler = (values) => {
    switch (props.type) {
      case "add":
        createKnowledge(values);
        break;
      case "edit":
        if (file) uploadFile("ナレッジを更新しました。")
        break;
      default:
    }
  };

  return (
    <main className="flex-grow overflow-auto flex flex-col mb-4">
      <div className="m-3 p-4 bg-white rounded-lg">
        <div className="flex items-center gap-2 mb-4">
          <button onClick={() => { setIsOpen(true) }} className="p-1 md:hidden "><AlignJustifyIcon size={18} /></button>
          <h3 className="text-lg font-semibold ">
            {props.type === "edit" ? "編集" : "新規登録"}
          </h3>
        </div>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
          >
            <FormInput
              name="title"
              type="text"
              placeholder="ナレッジ名"
              className="w-full p-2 mb-2 border border-gray-300 rounded"
              readOnly={props.type === "edit"}
            />
            <div className="mb-2">
              <textarea
                {...register("content")}
                placeholder="ナレッジ説明"
                className="w-full p-2 mb-2 border border-gray-300 rounded"
                rows={3}
                readOnly={props.type === "edit"}
              />
            </div>
            {props.type === "edit" &&
              <div className="mb-2">
                <label htmlFor="avatar">ドキュメント</label>
                { documents.map((item, i) =>
                  <div className="w-full px-4 text-gray-600">
                    { item.title }
                  </div>
                )}
                <input
                  className="appearance-none rounded-none relative block w-full mt-2 px-3 py-2 border
                  placeholder-gray-500 text-gray-900 rounded-t-md
                    focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                  type="file"
                  accept=".txt,.md,.pdf,.html,.xlsx,.xls,.docx,.csv,.eml,.msg,.pptx,.xml,.epub,.ppt,.htm"
                  onChange={handleFileChange}
                  ref={fileInputRef} 
                />
                <p className="mt-2 text-gray-600">
                  TXT, MARKDOWN, PDF, HTML, XLSX, XLS, DOCX, CSV, EML, MSG, PPTX, XML, EPUB, PPT, MD, HTMをサポートしています。1つあたりの最大サイズは15MBです。
                </p>                
              </div>   
            }
            <div className="flex justify-end">
              <button
                className="px-4 py-2 bg-gray-400 text-white rounded mr-2"
                type="button"
                onClick={() => {
                  navigate("/admin/knowledges")
                }}
              >
                キャンセル
              </button>
              {props.type === "edit" && (
                <button
                  className="px-4 py-2 bg-red-600 text-white rounded mr-2"
                  type="button"
                  onClick={() => {
                    if (window.confirm("削除しますか？"))
                      deleteKnowledge(knowledgeId);
                  }}
                >
                  削除
                </button>
              )}
              <button
                className="px-4 py-2 bg-indigo-600 text-white rounded"
                type="submit"
              >
                保存
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </main>
  );
};

export default Knowledge;
