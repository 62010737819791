import { ChevronDown } from 'lucide-react'
import * as React from 'react'

export const Accordion = ({
  className,
  children,
  defaultState = false,
  triggerClass,
  contentClass,
  arrowClass,
  ...props
}) => {

  let initialState

  if (defaultState) {
    initialState = defaultState
  } else {
    initialState = false;
  }

  const [open, setOpen] = React.useState(initialState)
  // const [label, content] = React.Children.toArray(children);
  const [header, content] = React.Children.toArray(children);

  const toggle = () => {
    setOpen(!open)
  }

  return (
    <div className={className || 'flex flex-col'} {...props}>
      <div className="flex justify-between">
        {header}
        <button
          data-state={open ? 'open' : 'closed'}
          onClick={toggle}
          className={`mt-1 flex justify-between items-center
            transition-all ease-in-out duration-200
            } ${triggerClass || ''}`}
            >
          <ChevronDown
              size={16}
              className={`${open ? '' : '-rotate-90'} transition-transform duration-200 ${arrowClass || ''}`}
          />
        </button>
      </div>
      <div
        className={`transition-all ${contentClass || ''}`}
      >
        {open && content}
      </div>
    </div>
  )
}
