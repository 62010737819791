import { useState, useRef, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Send, AlignJustifyIcon } from "lucide-react";
import { object, string } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import {
  useCreateChatMutation,
  useUpdateChatMutation,
  useGetReplyFromAIMutation
} from "../redux/api/chatApi";
import { ChatMessageActions } from "../components/CopyMessageBtn";
import { IconStop } from '../components/ui/icons';
import { useChat } from "../lib/hooks/use-chat";
import { useSidebar } from "../lib/hooks/use-sidebar";
import {
  useGetKnowledgesQuery  
} from "../redux/api/knowledgeApi";

const chatSchema = object({
  message: string().min(1, "メッセージを入力してください。"),
});

const MessageDisplay = ({ message }) => {
  const content = message?.content || '';
  const parts = content.split('\n\n引用:\n');
  const mainText = parts[0];
  const citations = parts[1]?.split('\n').filter(Boolean) || [];

  // Function to convert text into elements with links
  const processText = (text) => {
    // Split the text into segments and process each part
    const segments = [];
    let lastIndex = 0;

    // Email regex
    const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
    // URL regex
    const urlRegex = /(https?:\/\/[^\s]+)/gi;

    // Find all matches using a combined regex
    const combinedRegex = new RegExp(`(${emailRegex.source}|${urlRegex.source})`, 'gi');
    
    const matches = Array.from(text.matchAll(combinedRegex));

    matches.forEach((match, index) => {
      // Add text before the match
      if (match.index > lastIndex) {
        segments.push(text.substring(lastIndex, match.index));
      }

      // Process the matched text
      const matchedText = match[0];
      if (matchedText.match(emailRegex)) {
        segments.push(
          <a
            key={`email-${index}`}
            href={`mailto:${matchedText}`}
            className="text-blue-600 hover:text-blue-800 hover:underline"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = `mailto:${matchedText}`;
            }}
          >
            {matchedText}
          </a>
        );
      } else if (matchedText.match(urlRegex)) {
        segments.push(
          <a
            key={`url-${index}`}
            href={matchedText}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 hover:underline"
          >
            {matchedText}
          </a>
        );
      }

      lastIndex = match.index + matchedText.length;
    });

    // Add remaining text
    if (lastIndex < text.length) {
      segments.push(text.substring(lastIndex));
    }

    return segments;
  };

  return (
    <div className="flex flex-col gap-2">
      <div>
        {mainText.split('\n').map((line, index) => (
          <span key={index}>
            {processText(line)}
            <br />
          </span>
        ))}
      </div>
      
      {citations.length > 0 && (
        <div className="mt-2">
          <div className="text-sm text-gray-500 mb-1">引用</div>
          <div className="flex flex-col gap-1">
            {citations.map((citation, index) => (
              <div 
                key={index}
                className="flex items-center gap-2 bg-gray-50 p-2 rounded-md border border-gray-200"
              >
                <svg 
                  className="w-4 h-4 text-gray-400"
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" 
                  />
                </svg>
                <span className="text-sm text-gray-600">{citation}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};


const ChatPage = ({ isDifyMode, studio }) => {
  const user = useSelector((state) => state.userState.user);
  const { isGenerating, setIsGenerating } = useChat();
  const { setIsOpen } = useSidebar();

  const { data: knowledgeList, isError, error } = useGetKnowledgesQuery();

  const methods = useForm({
    resolver: zodResolver(chatSchema),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful, errors },
  } = methods;

  const { chatId, templateId } = useParams();
  const navigate = useNavigate();

  const [messages, setMessages] = useState([]);
  const [templateTitle, setTemplateTitle] = useState("Free");
  const [model, setModel] = useState("ChatGPT");
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [getReply, replyInfo] = useGetReplyFromAIMutation();
  const [createChat, createInfo] = useCreateChatMutation();
  const [updateChat, updateInfo] = useUpdateChatMutation();

  useEffect(() => scrollToBottom(), [messages]);
  useEffect(() => {
    if (knowledgeList && knowledgeList.length > 0) {
      setModel(knowledgeList[0].id);
    }
  }, [knowledgeList]);

  useEffect(() => {
    if (templateId !== "free")
      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/template/${templateId}`,
        { credentials: "include" }
      )
        .then((res) => res.json())
        .then(({ data }) => {
          if (data)
          setTemplateTitle(data.title);
        });
    else setTemplateTitle("Free");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId]);

  useEffect(() => {
    if (chatId !== "free")
      fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/api/chat/${chatId}`, {
        credentials: "include",
      })
        .then((res) => res.json())
        .then(({ data }) => {
          if (data) {
            setMessages([...JSON.parse(data.messages)]);
            setModel(data.model );
          }
        });
    else setMessages([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  useEffect(() => {
    if (createInfo.isSuccess) {
      navigate(`/chat/${createInfo.data.chatId}/template/${templateId}`);
    }

    if (createInfo.isError && createInfo.error.data) {
      if (Array.isArray(createInfo.error.data.error)) {
        createInfo.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(createInfo.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createInfo.isLoading]);

  useEffect(() => {

    if (updateInfo.isError && updateInfo.error.data) {
      if (Array.isArray(updateInfo.error.data.error)) {
        updateInfo.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(updateInfo.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateInfo.isLoading]);

  useEffect(() => {
    if (replyInfo.isSuccess) {
      updateChat({ id: chatId, chat: replyInfo.data });
      if (isGenerating) setMessages([...messages, replyInfo.data]);
      setIsGenerating(false); // Reset generating state
    }

    if (replyInfo.isError) {
      setIsGenerating(false); // Reset generating state
      if (Array.isArray(replyInfo.error.data.error)) {
        replyInfo.error.data.error.forEach((el) =>
          toast.error(el.message, { position: "top-right" })
        );
      } else {
        toast.error(replyInfo.error.data.message, { position: "top-right" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replyInfo.isLoading]);


  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const handleSendMessage = async (values) => {
    if (chatId === "free") {
      setIsGenerating(true); // Set generating state to true
      try {
        createChat({
          templateId: templateId === "free" ? null : templateId,
          message: values.message,
          is_company: isDifyMode,
          model,
          type: "user"
        });
        getReply({
          templateId: templateId === "free" ? null : templateId,
          message: values.message,
          model,
          studio: isDifyMode ? studio : 1
        })
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Message generation was aborted');
        } else {
          // Handle other errors
          toast.error("An error occurred while sending the message.");
        }
      }
    } else {
      setIsGenerating(true); // Set generating state to true
      try {
        updateChat({ id: chatId, chat: { content: values.message, type: "user" } });
        setMessages([...messages, {content: values.message, time: new Date(), type: "user"}]);
        getReply({
          templateId: templateId === "free" ? null : templateId,
          message: values.message,
          model,
          studio: isDifyMode ? studio : 1
        })
      } catch (error) {
        toast.error("An error occurred while sending the message.");
      }
    }
  };

  const handleCancelMessage = () => {
    setIsGenerating(false); // Reset generating state
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <div className="flex-grow flex flex-col overflow-hidden">
      <header className="bg-white shadow-sm p-4 flex justify-between items-center">
        <div className="flex items-center gap-2">
          <button onClick={() => { setIsOpen(true) }} className="p-1 md:hidden "><AlignJustifyIcon size={18} /></button>
          <h2 className="text-xl font-semibold text-gray-800">
            AIMentor - {templateTitle}
          </h2>
        </div>
        { isDifyMode &&
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={model}
            onChange={(event) => setModel(event.target.value)}
          >
            { knowledgeList && knowledgeList.map(item =>
              <option key={item.id} value={item.id}>{item.title}</option>
            )}
          </select>
        }
      </header>

      <main className="flex-grow overflow-hidden flex flex-col">
        <div className="flex-grow overflow-auto p-4">
          {messages.map((message, index) => (
            <div
              key={`message-item-${index}`}
              className={`mb-4 flex group ${
                message.type === "user" ? "justify-end" : "justify-start"
              }`}
            >
              <div className="flex items-center max-w-xs lg:max-w-md">
                {message.type === "assistant" && (
                  <img
                    src={
                      message?.model === "ChatGPT"
                        ? "/assets/chatgpt.png"
                        : message?.model === "Claude"
                        ? "/assets/claude.png"
                        : "/assets/llama.png"
                    }
                    alt="ChatGPT"
                    className="w-8 h-8 rounded-full mr-2"
                  />
                )}
                <div className={`flex flex-col ${
                message.type === "user" ? "items-end" : "items-start"
              }`}>
                  <div
                    className={`p-3 rounded-lg relative ${
                      message.type === "user"
                        ? "bg-blue-500 text-white"
                        : "bg-gray-200 text-gray-800"
                    }`}
                  >
                    <MessageDisplay message={{ content: message.content }} />
                    <ChatMessageActions className="md:!right-0" message={message.content} />
                  </div>
                  <div>{formatDate(new Date(message.time))}</div>
                </div>

                {message.type === "user" && (
                  <img
                    src={user.avatar ? `${process.env.REACT_APP_SERVER_ENDPOINT}${user.avatar}` : "/assets/user.png"}
                    alt={user.name}
                    className="w-8 h-8 rounded-full ml-2"
                  />
                )}
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>

        <FormProvider {...methods}>
          <div className="p-4 bg-white border-t">
            <form
              noValidate
              onSubmit={handleSubmit(handleSendMessage)}
              className="flex items-start w-full"
            >
              <textarea
                {...register("message")}
                type="text"
                placeholder="メッセージを入力してください..."
                className="flex-grow p-2 border  rounded-l-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault(); // Prevent new line
                    handleSubmit(handleSendMessage)(); // Trigger form submission
                  }
                }}
                disabled={isGenerating}
              />
              {
                isGenerating ?
                <button
                  type="button"
                  className="ml-2 p-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                  onClick={handleCancelMessage}
                >
                  <IconStop />
                </button>
                :
                <button
                  type="submit"
                  className="p-2 bg-indigo-600 text-white rounded-r-md hover:bg-indigo-700"
                  onClick={() => {
                    if (
                      errors["message"] &&
                      errors["message"].message.length !== 0
                    )
                      toast.error(errors["message"].message);
                  }}
                >
                  <Send size={20} />
                </button>
              }
            </form>
          </div>
        </FormProvider>
      </main>
    </div>
  );
};



export default ChatPage;
