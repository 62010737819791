import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT;

export const studioApi = createApi({
  reducerPath: "studioApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/admin/studio/`,
  }),
  tagTypes: ["Studios"],
  endpoints: (builder) => ({
    createStudio: builder.mutation({
      query(data) {
        return {
          url: "",
          method: "POST",
          credentials: "include",
          body: data,
        };
      },
      invalidatesTags: [{ type: "Studios", id: "LIST" }],
    }),
    getStudios: builder.query({
      query() {
        return {
          url: "",
          credentials: "include",
        };
      },
      transformResponse: (result) => result.data.studios,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Studios", id })),
              { type: "Studios", id: "LIST" },
            ]
          : [{ type: "Studios", id: "LIST" }],
    }),
    updateStudio: builder.mutation({
      query({ id, studio }) {
        return {
          url: `${id}`,
          method: "PATCH",
          credentials: "include",
          body: studio,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "Studios", id },
              { type: "Studios", id: "LIST" },
            ]
          : [{ type: "Studios", id: "LIST" }],
    }),
    deleteStudio: builder.mutation({
      query(id) {
        return {
          url: `${id}`,
          method: "DELETE",
          credentials: "include",
        };
      },
      invalidatesTags: [{ type: "Studios", id: "LIST" }],
    }),
  }),
});

export const {
  useCreateStudioMutation,
  useGetStudiosQuery,
  useUpdateStudioMutation,
  useDeleteStudioMutation,
} = studioApi;
