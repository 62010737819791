import { useState, useRef, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Send } from "lucide-react";
import { object, string } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import ReactMarkdown from "react-markdown";

import { ChatMessageActions } from "../components/CopyMessageBtn";
import { IconStop } from '../components/ui/icons';

const chatSchema = object({
  message: string().min(1, "メッセージを入力してください。"),
});

const MessageDisplay = ({ message }) => {
  return (
    <ReactMarkdown >
      {message.content}
    </ReactMarkdown>
  );
};


const ChatBotPage = () => {
  const { isGenerating, setIsGenerating } = useState(false);

  const methods = useForm({
    resolver: zodResolver(chatSchema),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful, errors },
  } = methods;



  const [messages, setMessages] = useState([]);
  const [knowledges, setKnowledges] = useState([]);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };


  useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/api/chat/knowledges`, {
            credentials: "include",
        })
        .then((res) => res.json())
        .then(({ data }) => {
            if (data?.knowledges)
                setKnowledges([...data.knowledges]);
        });
  }, [])

  useEffect(() => scrollToBottom(), [messages]);

    //   fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/api/chat/${chatId}`, {
    //     credentials: "include",
    //   })
    //     .then((res) => res.json())
    //     .then(({ data }) => {
    //       setMessages([...JSON.parse(data.messages)]);
    //       setModel(data.model );
    //     });

    const handleSendMessage = async (values) => {
        const newMsg = {
            content: values.message,
            type: "user",
            time: new Date(),
        };
        const messageList = [...messages, newMsg];
        setMessages(messageList);

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_ENDPOINT}/api/chat/bot`,
            {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({message: newMsg})
            }
        )
            .then((response) => response.json())
            .then(({ data }) => data);
        setMessages(prev => [...prev, response]);

        return response;
    }
    
    const handleCancelMessage = () => {

    }

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

  return (
    <div className="h-[100vh] flex flex-col overflow-hidden">
      <header className="bg-white shadow-sm p-4 flex justify-between items-center">
        <h2 className="text-xl font-semibold text-gray-800">
          Aimentor
        </h2>
      </header>
      <main className="flex-grow overflow-hidden flex flex-col">
        <div className="flex-grow overflow-auto p-4">
          <div className="mb-4 flex group justify-start">
              <div className="flex items-center max-w-xs lg:max-w-md">
                  <img
                    src="/assets/bot.png"
                    alt="Bot"
                    className="w-8 h-8 rounded-full mr-2"
                  />
                <div className="flex flex-col items-start">
                  <div className="p-3 rounded-lg relative bg-gray-200 text-gray-800" >
                    <div>AIが皆さんの質問にお答えします。<br/>市に関する事ならなんでも聞いてください。</div>
                  </div>
                </div>
              </div>
            </div>
          {messages.map((message, index) => (
            <div
              key={`message-item-${index}`}
              className={`mb-4 flex group ${
                message.type === "user" ? "justify-end" : "justify-start"
              }`}
            >
              <div className="flex items-center max-w-xs lg:max-w-md">
                {message.type === "assistant" && (
                  <img
                    src="/assets/bot.png"
                    alt="Bot"
                    className="w-8 h-8 rounded-full mr-2"
                  />
                )}
                <div className={`flex flex-col ${
                message.type === "user" ? "items-end" : "items-start"
              }`}>
                  <div
                    className={`p-3 rounded-lg relative ${
                      message.type === "user"
                        ? "bg-blue-500 text-white"
                        : "bg-gray-200 text-gray-800"
                    }`}
                  >
                    <MessageDisplay message={{ content: message.content }} />
                    <ChatMessageActions className="md:!right-0" message={message.content} />
                  </div>
                  <div>{formatDate(new Date(message.time))}</div>
                </div>

                {message.type === "user" && (
                  <img
                    src="/assets/user.png"
                    alt="avatar"
                    className="w-8 h-8 rounded-full ml-2"
                  />
                )}
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
        {
            messages.length === 0 &&
            <div className="py-4 px-8 flex flex-col gap-2">
              {
                knowledges?.slice(0,2).map(k => (
                    <button className="flex-1 py-4 px-6 bg-slate-500 rounded-lg text-white text-left">
                        <p className="font-bold">{k?.title}</p>
                        <p>{k?.content}</p>
                    </button>
                ))
              }
            </div>
        }

        <FormProvider {...methods}>
          <div className="p-4 bg-white border-t">
            <form
              noValidate
              onSubmit={handleSubmit(handleSendMessage)}
              className="flex items-start w-full"
            >
              <textarea
                {...register("message")}
                type="text"
                placeholder="メッセージを入力してください..."
                className="flex-grow p-2 border  rounded-l-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault(); // Prevent new line
                    handleSubmit(handleSendMessage)(); // Trigger form submission
                  }
                }}
                disabled={isGenerating}
              />
              {
                isGenerating ?
                <button
                  type="button"
                  className="ml-2 p-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                  onClick={handleCancelMessage}
                >
                  <IconStop />
                </button>
                :
                <button
                  type="submit"
                  className="p-2 bg-indigo-600 text-white rounded-r-md hover:bg-indigo-700"
                  onClick={() => {
                    if (
                      errors["message"] &&
                      errors["message"].message.length !== 0
                    )
                      toast.error(errors["message"].message);
                  }}
                >
                  <Send size={20} />
                </button>
              }
            </form>
          </div>
        </FormProvider>
      </main>
    </div>
  );
};



export default ChatBotPage;
